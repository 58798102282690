import request from '@/plugins/axios'

// 获取积分商品列表
export const apiRedpacketGoodsLists = (params: any) =>
  request.get('/redpacket.redpacket_goods/lists', { params })

// 商品分类/单位/供货商/品牌/运费模板列表
// 商品列表
export const apiOtherList = (params: any) => request.get('/redpacket.redpacket_goods/otherList', { params })

// 添加积分商品
export const apiRedpacketGoodsAdd = (params: any) =>
  request.post('/redpacket.redpacket_goods/add', params)

// 编辑积分商品
export const apiRedpacketGoodsEdit = (params: any) =>
  request.post('/redpacket.redpacket_goods/edit', params)

// 删除积分商品
export const apiRedpacketGoodsDel = (params: any) =>
  request.post('/redpacket.redpacket_goods/del', params)

// 切换积分商品状态
export const apiRedpacketGoodsStatus = (params: any) =>
  request.post('/redpacket.redpacket_goods/status', params)

// 获取积分商品详情
export const apiRedpacketGoodsDetail = (params: any) =>
  request.get('/redpacket.redpacket_goods/detail', { params })

// 兑换订单列表
export const apiRedpacketOrderLists = (params: any) =>
  request.get('/redpacket.redpacket_order/lists', { params })

// 兑换订单详情
export const apiRedpacketOrderDetail = (params: any) =>
  request.get('/redpacket.redpacket_order/detail', { params })

// 发货信息
export const apiRedpacketOrderDeliveryInfo = (params: any) =>
  request.get('/redpacket.redpacket_order/deliveryInfo', { params })

// 发货
export const apiRedpacketGoodsDelivery = (params: any) =>
  request.post('/redpacket.redpacket_order/delivery', params)

// 确认收货
export const apiRedpacketGoodsConfirm = (params: any) =>
  request.post('/redpacket.redpacket_order/confirm', params)

// 确认收货
export const apiRedpacketGoodsCancel = (params: any) =>
  request.post('/redpacket.redpacket_order/cancel', params)

// 物流查询
export const apiRedpacketGoodsLogistics = (params: any) =>
  request.get('/redpacket.redpacket_order/logistics', { params })

// 新增品牌
export const apiRedpacketCategoryAdd = (data: any) => request.post('/redpacket.redpacket_category/add', data)

// 品牌列表
export const apiRedpacketCategoryLists = (params: any) => request.get('/redpacket.redpacket_category/lists', { params })

// 修改品牌状态
export const apiRedpacketCategoryStatus = (data: any) => request.post('/redpacket.redpacket_category/status', data)

// 删除品牌
export const apiRedpacketCategoryDel = (data: any) => request.post('/redpacket.redpacket_category/del', data)

// 商品详情
export const apiRedpacketCategoryDetail = (id: number) => request.get('/redpacket.redpacket_category/detail', { params: { id } })

// 删除品牌
export const apiRedpacketCategoryEdit = (data: any) => request.post('/redpacket.redpacket_category/edit', data)

// 红包商品分类列表
export const apiCategoryLists = (params: any) => request.get('/redpacket.redpacket_category/lists', { params })

// 红包折扣列表
export const apiDiscountLists = (params: any) => request.get('/redpacket.redpacket_goods/discountList', { params })

// 批量修改红包商品
export const apiBatchEdit = (params: any) =>
  request.post('/redpacket.redpacket_goods/batchEdit', params)
